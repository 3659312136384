import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.AUTH_SERVER_PREFIX}/applicant-profile`;
const endpoints = {
    list: () => `${RESOURCE_ENDPOINT}`,
    getById: (id: any) => `${RESOURCE_ENDPOINT}/${id}`,
    getByApplicantId: (applicantId: any) => `${RESOURCE_ENDPOINT}/applicant/${applicantId}`,
    getByUserId: (userId: any) => `${RESOURCE_ENDPOINT}/applicant/details/${userId}`,
    create: () => `${RESOURCE_ENDPOINT}`,
    update: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
    updatePartial: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
    delete: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
    bulk: () => `${RESOURCE_ENDPOINT}/bulk`,
    dropdown: () => `${RESOURCE_ENDPOINT}/dropdown`,
    searchProfile: () => `${RESOURCE_ENDPOINT}/searchProfile`,
    searchProfileByRcoNumber: () => `${RESOURCE_ENDPOINT}/searchProfileByRcoNumber`,
    updateRCOData: () => `${RESOURCE_ENDPOINT}/update-rco-data`,
};

export default class ApplicantProfileApi {
    public list = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.list();

        return HttpService.get(url, params, headers);
    };

    public getById = (id: any): AxiosPromise<any> => {
        const url = endpoints.getById(id);
        return HttpService.get(url);
    };

    public getByApplicantId = (applicantId: any): AxiosPromise<any> => {
        const url = endpoints.getByApplicantId(applicantId);
        return HttpService.get(url);
    };
    public getByUserId = (userId: any): AxiosPromise<any> => {
        const url = endpoints.getByUserId(userId);
        return HttpService.get(url);
    };

    public create = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.create();
        console.log(payload);
        return HttpService.post(url, payload, params, headers);
    };

    public update = (
        id: any,
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.update(id);
        return HttpService.put(url, payload, params, headers);
    };

    public updatePartial = (
        id: any,
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.updatePartial(id);
        return HttpService.patch(url, payload, params, headers);
    };

    public delete = (id: any, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.delete(id);
        return HttpService.delete(url, params, headers);
    };

    public bulk = (
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.bulk();
        return HttpService.post(url, payload, params, headers);
    };
    public dropdown = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.dropdown();
        return HttpService.get(url, params, headers);
    };

    public searchProfile = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.searchProfile();
        return HttpService.get(url, params, headers);
    };

    public searchProfileByRcoNumber = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.searchProfileByRcoNumber();
        return HttpService.get(url, params, headers);
    };

    public updateRCOData = (
        id: any,
        payload = {},
        params = {},
        headers = {}
    ): AxiosPromise<any> => {
        const url = endpoints.updateRCOData();
        return HttpService.patch(url, payload, params, headers);
    };
}
